import { format } from 'date-fns';

const consts = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL || "https://api.raindropcentral.com",
    BASE_URL_Local: process.env.REACT_APP_API_BASE_URL || "http://localhost:5000",
    Roles: {
        'super-admin': '6657a7f1f757c7129787ffae',
        'admin': '6657a7f1f757c7129787ffb2',
        'user': '6657a7f1f757c7129787ffb5',
    },
    TimeFormat: {
        StandardFormat: '12 hour',
        MilitaryFormat: '24 hour'
    },
    PasswordStrength: {
        Easy: '^(?=.*[A-Z])(?=\\S+$).{8,50}$',
        Medium: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{8,50}$',
        Strong: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,50}$'
    },
    formatDate: (dateString) => format(new Date(dateString), 'MMM dd, yyyy'),
    monthsFull: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ],
    monthsShort: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
}

export default consts;
