import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { login, logout } from '../slices/authSlice';
import consts from '../../constants/consts';

const baseQuery = fetchBaseQuery({
    baseUrl: consts.BASE_URL || 'https://api.raindropcentral.com',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        const refreshToken = api.getState().auth.refreshToken;
        if (refreshToken) {
            const refreshResult = await api.dispatch(usersApi.endpoints.refreshToken.initiate(refreshToken));
            if (refreshResult.data) {
                const { token, refreshToken: newRefreshToken } = refreshResult.data;
                api.dispatch(login({ token, refreshToken: newRefreshToken }));
                result = await baseQuery(args, api, extraOptions);
            } else {
                api.dispatch(logout());
            }
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['User', 'UsersList', 'UserStats', 'UserDetails'],
    endpoints(builder) {
        return {
            checkSession: builder.query({
                query: () => '/api/user/auth/me',
                providesTags: [{ type: 'UserDetails', id: 'USER' }],
            }),
            registerUser: builder.mutation({
                query: (user) => ({
                    url: '/api/user/auth/register',
                    method: 'POST',
                    body: user,
                }),
                invalidatesTags: [{ type: 'UsersList', id: 'LIST' }],
            }),
            loginUser: builder.mutation({
                query: (credentials) => ({
                    url: '/api/user/auth/login',
                    method: 'POST',
                    body: credentials,
                }),
            }),
            logoutUser: builder.mutation({
                query: () => ({
                    url: '/api/user/auth/logout',
                    method: 'POST',
                }),
                invalidatesTags: ['User', 'UserDetails', 'UserStats'],
            }),
            updateDetails: builder.mutation({
                query: (details) => ({
                    url: '/api/user/auth/updatedetails',
                    method: 'PUT',
                    body: details,
                }),
                invalidatesTags: [{ type: 'UserDetails', id: 'USER' }],
            }),
            updateProfileImage: builder.mutation({
                query: (formData) => ({
                    url: '/api/user/auth/updateProfile',
                    method: 'PUT',
                    body: formData,
                }),
                invalidatesTags: [{ type: 'UserDetails', id: 'USER' }],
            }),
            resetProfileImage: builder.mutation({
                query: () => ({
                    url: '/api/user/auth/resetProfile',
                    method: 'PUT'
                }),
                invalidatesTags: [{ type: 'UserDetails', id: 'USER' }],
            }),
            updatePassword: builder.mutation({
                query: (passwordDetails) => ({
                    url: '/api/user/auth/updatepassword',
                    method: 'PUT',
                    body: passwordDetails,
                }),
                invalidatesTags: [{ type: 'UserDetails', id: 'USER' }],
            }),
            forgotPassword: builder.mutation({
                query: (email) => ({
                    url: '/api/user/auth/forgotpassword',
                    method: 'POST',
                    body: email,
                }),
            }),
            resetPassword: builder.mutation({
                query: ({ token, email, newPassword, confirmPassword }) => ({
                    url: '/api/user/auth/resetpassword',
                    method: 'PUT',
                    body: { token, email, newPassword, confirmPassword },
                }),
            }),
            authenticateMinecraft: builder.mutation({
                query: (authCode) => ({
                    url: '/api/minecraft/authenticate',
                    method: 'POST',
                    body: authCode,
                }),
                invalidatesTags: [{ type: 'UserDetails', id: 'USER' }],
            }),
            checkMinecraftLinkStatus: builder.query({
                query: () => '/api/minecraft/status',
                providesTags: [{ type: 'UserDetails', id: 'MINECRAFT_STATUS' }],
            }),
            fetchUserStats: builder.query({
                // Endpoint to fetch a user's global stats
                query: (id) => `/api/user/auth/users/${id}/stats/global`,
                providesTags: (result, error, id) => [{ type: 'UserStats', id }],
            }),
            refreshToken: builder.mutation({
                query: (refreshToken) => ({
                    url: '/api/user/auth/refresh',
                    method: 'POST',
                    body: { refreshToken },
                }),
            }),
        };
    },
});

export const {
    useCheckSessionQuery,
    useRegisterUserMutation,
    useLoginUserMutation,
    useLogoutUserMutation,
    useUpdateDetailsMutation,
    useUpdateProfileImageMutation,
    useResetProfileImageMutation,
    useUpdateUserMutation,
    useUpdatePasswordMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useAuthenticateMinecraftMutation,
    useCheckMinecraftLinkStatusQuery,
    useFetchUserStatsQuery,
    useRefreshTokenMutation,
} = usersApi;