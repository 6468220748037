import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    errors: [],
};

export const errorSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        logError: (state, action) => {
            state.errors.push(action.payload);
        },
    },
});

export const { logError } = errorSlice.actions;

export default errorSlice.reducer;
