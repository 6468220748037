// src/store/slices/statsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { statsApi } from '../apis/statsApi';

const initialState = {
  pinnedStats: [],
  selectedPlugins: [],
  pluginPages: {},
  pluginPageSizes: {},
  searchTerm: '',
  searchResults: {},
  isSearching: false,
};

export const searchStatistics = createAsyncThunk(
    'stats/searchStatistics',
    async ({ searchTerm, selectedPlugins }, { getState, dispatch }) => {
      const state = getState();
      const results = {};
  
      for (const plugin of selectedPlugins) {
        const pageSize = state.stats.pluginPageSizes[plugin] || 9;
        const response = await dispatch(
          statsApi.endpoints.fetchPluginStatistics.initiate({
            plugin,
            page: 1,
            pageSize: pageSize,
            pinnedStats: state.stats.pinnedStats,
            searchTerm: searchTerm,
          })
        );
  
        if (response.data && response.data.success) {
          results[plugin] = response.data.data.map(stat => ({
            ...stat,
            plugin,
            isPinned: state.stats.pinnedStats.includes(stat.combinedType)
          }));
        }
      }
  
      return results;
    }
  );

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    togglePinnedStat: (state, action) => {
      const statType = action.payload;
      const index = state.pinnedStats.indexOf(statType);
      if (index > -1) {
        state.pinnedStats.splice(index, 1);
      } else {
        state.pinnedStats.push(statType);
      }
    },
    setPinnedStats: (state, action) => {
      state.pinnedStats = action.payload;
    },
    setSelectedPlugins: (state, action) => {
      state.selectedPlugins = action.payload;
    },
    toggleSelectedPlugin: (state, action) => {
      const plugin = action.payload;
      const index = state.selectedPlugins.indexOf(plugin);
      if (index > -1) {
        state.selectedPlugins.splice(index, 1);
        delete state.pluginPages[plugin];
        delete state.pluginPageSizes[plugin];
      } else {
        state.selectedPlugins.push(plugin);
        state.pluginPages[plugin] = 1;
        state.pluginPageSizes[plugin] = 9;
      }
    },
    setPluginPage: (state, action) => {
      const { plugin, page } = action.payload;
      state.pluginPages[plugin] = page;
    },
    setPluginPageSize: (state, action) => {
      const { plugin, pageSize } = action.payload;
      state.pluginPageSizes[plugin] = pageSize;
      state.pluginPages[plugin] = 1; // Reset to first page when changing page size
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchStatistics.pending, (state) => {
        state.isSearching = true;
      })
      .addCase(searchStatistics.fulfilled, (state, action) => {
        state.isSearching = false;
        state.searchResults = action.payload;
      })
      .addCase(searchStatistics.rejected, (state) => {
        state.isSearching = false;
      });
  },
});


export const {
  togglePinnedStat,
  setPinnedStats,
  setSelectedPlugins,
  toggleSelectedPlugin,
  setPluginPage,
  setPluginPageSize,
  setSearchTerm,
} = statsSlice.actions;

export default statsSlice.reducer;

// Selectors
export const selectPinnedStats = (state) => state.stats.pinnedStats;
export const selectSelectedPlugins = (state) => state.stats.selectedPlugins;
export const selectPluginPages = (state) => state.stats.pluginPages;
export const selectPluginPageSizes = (state) => state.stats.pluginPageSizes;
export const selectSearchTerm = (state) => state.stats.searchTerm;
export const selectSearchResults = (state) => state.stats.searchResults;
export const selectIsSearching = (state) => state.stats.isSearching;

