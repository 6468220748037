import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import consts from '../../constants/consts';

const baseQuery = fetchBaseQuery({
    baseUrl: consts.BASE_URL || 'https://api.raindropcentral.com',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const statsApi = createApi({
    reducerPath: 'statsApi',
    baseQuery,
    tagTypes: ['Stats'],
    endpoints: (builder) => ({
        fetchGlobalUserStats: builder.query({
            query: () => '/api/global/data/user-stats',
            providesTags: ['Stats'],
        }),
        fetchMonthlyEarnings: builder.query({
            query: () => '/api/global/data/monthly-earnings',
            providesTags: ['Stats'],
        }),
        fetchMiningProfit: builder.query({
            query: () => '/api/global/data/mining-profit',
            providesTags: ['Stats'],
        }),
        fetchTotalEarnings: builder.query({
            query: () => '/api/global/data/total-earnings',
            providesTags: ['Stats'],
        }),
        fetchStatisticTypes: builder.query({
            query: () => '/api/global/data/stats/types',
            providesTags: ['Stats'],
        }),
        fetchPluginStatistics: builder.query({
            query: ({ plugin, page, pageSize, pinnedStats }) => ({
                url: `/api/global/data/stats/${plugin}`,
                method: 'POST',
                body: { page, pageSize: pageSize, pinnedStats },
            }),
            providesTags: (result, error, arg) => 
                result 
                    ? [{ type: 'Stats', id: arg.plugin }, 'Stats']
                    : ['Stats'],
        }),
    }),
});

export const {
    useFetchGlobalUserStatsQuery,
    useFetchMonthlyEarningsQuery,
    useFetchMiningProfitQuery,
    useFetchTotalEarningsQuery,
    useFetchPluginStatisticsQuery,
    useFetchStatisticTypesQuery,
} = statsApi;