import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { login, logout } from '../slices/authSlice';
import consts from 'src/constants/consts';

const API_BASE_URL = consts.BASE_URL || 'https://api.raindropcentral.com';

const serversBaseQuery = fetchBaseQuery({
    baseUrl: API_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await serversBaseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        const refreshToken = api.getState().auth.refreshToken;
        if (refreshToken) {
            const refreshResult = await api.dispatch(serversApi.endpoints.refreshToken.initiate(refreshToken));
            if (refreshResult.data) {
                const { token, refreshToken: newRefreshToken } = refreshResult.data;
                api.dispatch(login({ token, refreshToken: newRefreshToken }));
                result = await serversBaseQuery(args, api, extraOptions);
            } else {
                api.dispatch(logout());
            }
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const serversApi = createApi({
    reducerPath: 'serversApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Server', 'ServersList'],
    endpoints: (builder) => ({
        fetchServers: builder.query({
            query: () => '/api/server',
            providesTags: ['ServersList'],
            keepUnusedDataFor: 15, // Keeps data fresh for 15 seconds
            pollingInterval: 5000, // Fetches new data every 5 seconds
        }),
        createServer: builder.mutation({
            query: (server) => ({
                url: '/api/server',
                method: 'POST',
                body: server,
            }),
            invalidatesTags: ['ServersList'],
        }),
        getServerByUUID: builder.query({
            query: (uuid) => ({ url: `/api/server/${uuid}` }),
            providesTags: (result, error, uuid) => [{ type: 'Server', id: uuid }],
        }),
        updateServer: builder.mutation({
            query: ({ uuid, ...updateData }) => ({
                url: `/api/server/${uuid}`,
                method: 'PUT',
                body: updateData,
            }),
            invalidatesTags: (result, error, uuid) => [{ type: 'Server', id: uuid }, { type: 'ServersList' }],
        }),
        deleteServer: builder.mutation({
            query: (uuid) => ({
                url: `/api/server/${uuid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ServersList'],
        }),
    }),
});

export const {
    useFetchServersQuery,
    useCreateServerMutation,
    useGetServerByUUIDQuery,
    useUpdateServerMutation,
    useDeleteServerMutation,
} = serversApi;
