import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import consts from 'src/constants/consts';

const RSS_FEED_URL = consts.BASE_URL;

// Thunk for fetching RSS feed data
export const fetchRSSFeed = createAsyncThunk(
    'rssFeed/fetchRSSFeed',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${RSS_FEED_URL}/api/feeds/rss`);
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.response?.data.message || ' Failed to load feed updates! Unexpected error occurred.');
        }
    }
);

const initialState = {
    feed: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

export const rssFeedSlice = createSlice({
    name: 'rssFeed',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRSSFeed.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRSSFeed.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.feed = action.payload;
            })
            .addCase(fetchRSSFeed.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default rssFeedSlice.reducer;
