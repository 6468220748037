import React, { useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider, Box } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from './store/slices/authSlice';
import { useCheckSessionQuery } from './store/apis/usersApi';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/RTL';
import ScrollToTop from './layouts/ScrollToTop';
import Router from './routes/Router';
import Cookies from 'js-cookie';
import Spinner from './views/spinner/Spinner';

function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.customizer);
  const dispatch = useDispatch();
  const { data, error, isLoading, refetch } = useCheckSessionQuery();

  useEffect(() => {
    if (data && data.success && data.user) {
      dispatch(login({ user: data.user, token: data.token }));
    } else if (error) {
      // Check if the error status indicates an authentication issue
      if (error.status === 401 || error.originalStatus === 401) {
        console.error('Session validation error:', error);
        dispatch(logout());
      }
    }
  }, [data, error, dispatch]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ScrollToTop>{routing}</ScrollToTop>
      </RTL>
    </ThemeProvider>
  );
}

export default App;

