import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import getRoleNameById from "src/utils/roleUtils";
const selectUser = (state) => state.auth.user;
const selectIsAuthenticated = (state) => state.auth.isLoggedIn;


export default function ProtectedRoute({ children, isAdminRequired }) {
  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const location = useLocation();

  // Convert role ID to role name
  const roleName = user ? user.role.name : null;

  // Redirect unauthenticated users to the login page
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Redirect users without admin or super-admin roles when admin access is required
  // if (isAdminRequired && !(user?.role === "admin" || user?.role === "super-admin")) {
  if (isAdminRequired && !(roleName === "admin" || roleName === "super-admin")) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // If user is authenticated and has the correct role (if required), render the requested route
  return children;
}
