// src/store/slices/minecraftAuthSlice.js
import { createSlice } from '@reduxjs/toolkit';

const minecraftAuthSlice = createSlice({
    name: 'minecraftAuth',
    initialState: {
        isAuthenticated: false,
    },
    reducers: {
        setAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
    },
});

export const { setAuthenticated } = minecraftAuthSlice.actions;

export default minecraftAuthSlice.reducer;
