// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import rootReducer from './rootReducer';
import { topUsersApi } from './apis/topUsersApi';
import { usersApi } from './apis/usersApi';
import { adminApi } from './apis/adminApi';
import { serversApi } from './apis/serversApi';
import { playersApi } from './apis/playersApi';
import { statsApi } from './apis/statsApi';
import { minecraftApi } from './apis/minecraftApi';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['minecraftAuth', 'customizer','stats'] // Persisting only the selected slices
    // or use blacklist to exclude specific slices:
    // blacklist: ['rssFeed'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE']
            },
            immutableCheck: process.env.NODE_ENV !== 'production',
        })
            .concat(topUsersApi.middleware)
            .concat(usersApi.middleware)
            .concat(adminApi.middleware)
            .concat(serversApi.middleware)
            .concat(playersApi.middleware)
            .concat(minecraftApi.middleware)
            .concat(statsApi.middleware),
    devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
setupListeners(store.dispatch);