import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import consts from '../../constants/consts';

const baseQuery = fetchBaseQuery({
    baseUrl: consts.BASE_URL || 'https://api.raindropcentral.com',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const minecraftApi = createApi({
    reducerPath: 'minecraftApi',
    baseQuery,
    tagTypes: ['Minecraft'],
    endpoints: (builder) => ({
        fetchMinecraftProfile: builder.query({
            query: (uuid) => `/api/minecraft/profile/${uuid}`,
            providesTags: ['MinecraftProfile'],
        }),
    }),
});

export const {
    useFetchMinecraftProfileQuery,
} = minecraftApi;