import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { login, logout } from '../slices/authSlice';
import consts from '../../constants/consts';

const adminBaseQuery = fetchBaseQuery({
    baseUrl: consts.BASE_URL || 'https://api.raindropcentral.com',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await adminBaseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        const refreshToken = api.getState().auth.refreshToken;
        if (refreshToken) {
            const refreshResult = await api.dispatch(adminApi.endpoints.refreshToken.initiate(refreshToken));
            if (refreshResult.data) {
                const { token, refreshToken: newRefreshToken } = refreshResult.data;
                api.dispatch(login({ token, refreshToken: newRefreshToken }));
                result = await adminBaseQuery(args, api, extraOptions);
            } else {
                api.dispatch(logout());
            }
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const adminApi = createApi({
    reducerPath: 'adminApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Admin', 'AdminsList', 'User', 'UsersList'],
    endpoints: (builder) => ({
        fetchUsers: builder.query({
            query: () => '/api/admin/users',
            providesTags: ['UsersList'],
            keepUnusedDataFor: 15, // Keeps data fresh for 15 seconds
            pollingInterval: 5000, // Fetches new data every 5 seconds
        }),
        createUser: builder.mutation({
            query: (user) => ({
                url: '/api/admin/users',
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['UsersList'],
        }),
        getUser: builder.query({
            query: (userId) => ({ url: `/api/admin/users/${userId}` }),
            providesTags: (result, error, userId) => [{ type: 'User', id: userId }]
        }),
        updateUser: builder.mutation({
            query: ({ id, ...updateData }) => ({
                url: `/api/admin/users/${id}`,
                method: 'PUT',
                body: updateData,
            }),
            invalidatesTags: (result, error, id) => [{ type: 'User', id }, { type: 'UsersList' }],
        }),
        handleUser: builder.mutation({
            query: ({ id, data, method = 'GET' }) => {
                const queryDetails = {
                    url: `/api/admin/users/${id}`,
                    method: method,
                };
                if (method === 'PUT') {
                    queryDetails.body = data;
                }
                return queryDetails;
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'User', id }, { type: 'UsersList' }],
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/api/admin/users/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'UsersList' }],
        }),
        fetchAdminUsers: builder.query({
            query: () => '/api/admin/admins',
            providesTags: ['AdminsList'],
        }),
        toggleAdminStatus: builder.mutation({
            query: ({ userId }) => ({
                url: `/api/admin/admins/toggle/${userId}`,
                method: 'PUT',
            }),
            invalidatesTags: ['Admin', 'AdminsList'],
        }),
        modifyUserRole: builder.mutation({
            query: ({ userId, roleId }) => ({
                url: `/api/admin/admins/roles/${userId}`,
                method: 'PUT',
                body: { roleId },
            }),
            invalidatesTags: ['Admin', 'AdminsList'],
        }),
        promoteToLimitedSuperAdmin: builder.mutation({
            query: ({ userId, privileges }) => ({
                url: `/api/admin/admins/promote/${userId}`,
                method: 'PUT',
                body: { privileges },
            }),
            invalidatesTags: ['Admin', 'AdminsList'],
        }),
        delegateRoles: builder.mutation({
            query: ({ userId, role, privileges }) => ({
                url: `/api/admin/admins/delegate/${userId}`,
                method: 'PUT',
                body: { role, privileges },
            }),
            invalidatesTags: ['AdminsList'],
        }),
    }),
});

export const {
    useFetchUsersQuery,
    useCreateUserMutation,
    useGetUserQuery,
    useUpdateUserMutation,
    useHandleUserMutation,
    useDeleteUserMutation,
    useFetchAdminUsersQuery,
    useToggleAdminStatusMutation,
    useModifyUserRoleMutation,
    usePromoteToLimitedSuperAdminMutation,
    useDelegateRolesMutation,
} = adminApi;
