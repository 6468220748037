// src/store/apis/topUsersApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import consts from '../../constants/consts';

export const topUsersApi = createApi({
    reducerPath: 'topUsersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: consts.BASE_URL || 'https://api.raindropcentral.com',
    }),
    endpoints: (builder) => ({
        fetchTopUsers: builder.query({
            query: () => '/api/global/data/top-users',
        }),
    }),
});

export const { useFetchTopUsersQuery } = topUsersApi;
