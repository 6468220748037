import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const removeCookie = (name) => {
    // Remove for all paths
    Cookies.remove(name, { path: '' });
    Cookies.remove(name, { path: '/' });
    
    // Remove for current domain and all subdomains
    const domain = window.location.hostname;
    Cookies.remove(name, { domain: domain });
    Cookies.remove(name, { domain: '.' + domain });
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: false,
        user: null,
        token: Cookies.get('token') || localStorage.getItem('token') || null,
        refreshToken: null,
        error: null,
        lastLogoutTime: null,
    },
    reducers: {
        login: (state, action) => {
            console.log("Login reducer called");

            if (state.lastLogoutTime && Date.now() - state.lastLogoutTime < 5000) {
                state.error = "Please wait a moment before logging in again.";
                return;
            }

            const { user, token, refreshToken } = action.payload;
            state.user = user;
            state.token = token;
            state.refreshToken = refreshToken;
            state.isLoggedIn = true;
            state.error = null;
        },
        logout: (state, action) => {
            console.log("Logout reducer called");
            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            state.refreshToken = null;
            state.error = null;
            state.lastLogoutTime = Date.now();

            // Remove token cookie
            removeCookie('token');

            // Clear localStorage and sessionStorage
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            sessionStorage.clear();
        },
        loginFailed: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const { login, logout, loginFailed } = authSlice.actions;

export default authSlice.reducer;

// Selectors
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectUserName = (state) => state.auth.user?.username;
export const selectUserRole = (state) => state.auth.user?.role;
export const selectUserProfileImage = (state) => state.auth.user?.profileImage;
export const selectAuthToken = (state) => state.auth.token;
export const selectAuthError = (state) => state.auth.error;