// src/store/rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import { topUsersApi } from './apis/topUsersApi';
import { usersApi } from './apis/usersApi';
import { adminApi } from './apis/adminApi';
import { serversApi } from './apis/serversApi';
import { playersApi } from './apis/playersApi';
import { statsApi } from './apis/statsApi';
import customizerReducer from './customizer/CustomizerSlice';
import authReducer from './slices/authSlice';
import minecraftAuthReducer from './slices/minecraftAuthSlice';
import rssFeedReducer from './apis/rssFeedSlice';
import statsReducer from './slices/statsSlice';
import errorReducer from './slices/errorSlice';
import { minecraftApi } from './apis/minecraftApi';

const rootReducer = combineReducers({
    [topUsersApi.reducerPath]: topUsersApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [minecraftApi.reducerPath]: minecraftApi.reducer,
    [serversApi.reducerPath]: serversApi.reducer,
    [playersApi.reducerPath]: playersApi.reducer,
    [statsApi.reducerPath]: statsApi.reducer,
    customizer: customizerReducer,
    auth: authReducer,
    minecraftAuth: minecraftAuthReducer,
    rssFeed: rssFeedReducer,
    stats: statsReducer,
    errors: errorReducer,
});

export default rootReducer;
