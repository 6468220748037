import consts from 'src/constants/consts';

// const getRoleNameById = (roleId) => {
//     const roles = consts.Roles;
//     return Object.keys(roles).find(key => roles[key] === roleId) || "Role";
// }

// export default getRoleNameById;

// Updated getRoleNameById function
const getRoleNameById = (roleId) => {
    // Check if the roleId is already a role name
    if (roleId in consts.Roles) {
        return roleId; // It's already a readable role name
    }

    // Otherwise, find the readable role name from the mapping
    return Object.keys(consts.Roles).find(key => consts.Roles[key] === roleId) || "Role";
};

export default getRoleNameById;
